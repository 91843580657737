//Colors
$white-color: #ffffff;

//Pixels sizes
$Size-12px: 12px;
$Size-16px: 16px;

.Admin-form-login {
  input::placeholder {
    font-size: $Size-12px !important;
  }
  .title {
    padding: $Size-16px;
    font-weight: 600;
  }
  .btn-flat {
    background-color: #2165ae;
    color: $white-color;
  }
  .text-danger {
    color: lightpink !important;
    font-size: 55%;
  }
  .login-error {
    color: lightpink !important;
    font-size: 0.9rem;
    max-width: 300px;
  }
  .account-registration {
    backdrop-filter: blur(4px);
    font-style: normal;
    padding-top: $Size-16px;
    font-size: $Size-12px;
    text-align: center;
    line-height: 24px;
  }
}
