.dashboard {
  padding: 16px;
  .row-style-with-flexbox {
    display: flex;
    justify-content: space-between;
  }
  .dashboard-body {
    display: flex;
    justify-content: space-between;
    .main-form {
      width: 32%;
      .main-form-find-fixtures {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 300px;
        .main-form-find-fixtures-datepicker-field {
          text-align: center;
          .react-datepicker-wrapper {
            padding: 5px;
          }
        }
      }
      .main-form-edit-fixtures-pill-button {
        width: 100%;
        text-align: center;
        padding: 18px;
        .main-form-edit-fixtures-pill-badge {
          padding: 15px 30px;
          width: 100%;
          margin: 8px;
          span {
            display: flex;
            justify-content: space-around;
            i {
              width: 50%;
              justify-content: center;
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
      .main-form-tab-spacing {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .main-form-fixtures-button {
        width: 100%;
      }
      .main-form-upload-button {
        width: 100%;
      }
    }
    .main-list {
      width: 66%;
    }
  }
}

@media only screen and (max-width: 820px) {
  .dashboard-body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .main-form {
      min-width: 100%;
      margin-bottom: 16px;
    }
    .main-list {
      min-width: 100%;
    }
  }
}
