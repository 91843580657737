//Colors
$white-color: #ffffff;

.alert_action {
  width: 60%;
  margin: auto;
  margin-bottom: 16px;
}
.LoginPage-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background: rgba(255, 255, 255, 0.95);
  mix-blend-mode: normal;
  .LoginPage-layout {
    display: flex;
    color: $white-color;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #2b7ed7;
    border: 0.5px solid $white-color;
    box-sizing: border-box;
    border-radius: 60px;
    position: absolute;
    width: 376px;
    height: 357px;
    img {
      height: 24px;
    }
  }
}
