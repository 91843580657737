body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Open Sans", 'Segoe UI',
  //   'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  //   'Helvetica Neue', sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label {
  font-weight: bold;
  font-size: small;
  margin-bottom: 0.1rem;
}
